<template>
  <div class="chatroom">
    <div class="main" @click="clickChatroom" ref="chatPanel">
      <!-- 1__他人 0__自己 2__自助菜单 3__提示-->
      <template v-for="(item,index) in msgArrPanel">
        <!-- 别人的消息 -->
        <div v-if="item.type==1" class="msgitem other" :key="index">
          <img :src="item.avatarUrl" alt="头像" class="avatarUrl">
          <div class="other_msg">
            <div class="other_msg_name">{{item.name}}</div>
            <div class="other_msg_text" v-if="item.msgtype=='text'" v-html="dealMsg(item.msg)"></div>
            <div class="other_msg_text" v-if="item.msgtype=='image'">
              <img :src="item.msg" style="max-width: 4.16rem;height: auto;" @click="lookImage(item.msg)"
                @load="loadImg">
            </div>
            <div class="other_msg_goods" v-if="item.msgtype=='miniprogrampage'" @click="goGoods(item.msg.id)">
              <img :src="item.msg.thumb" class="msg_goods_image">
              <div class="msg_goods_info">
                <div class="msg_goods_name">{{item.msg.title}}</div>
                <div class="msg_goods_price">￥{{item.msg.goods_price}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单状态 -->
        <div v-if="item.type==2 && item.msgtype=='order'" :key="index" class="auto order">
          <div class="title">订单状态 {{item.data.state_desc?'：' + item.data.state_desc:''}}</div>
          <template v-if="item.data.id>0">
            <div class="o_goods">
              <div class="goods_imagebox">
                <img :src="item.data.extend_order_goods[0].goods_img" class="o_goods_image">
                <div class="goods_allnum">共{{item.data.goods_num}}件商品</div>
              </div>
              <div class="o_goods_info">
                <div class="o_goods_name">{{item.data.extend_order_goods[0].goods_title}}</div>
                <div class="o_goods_num">×{{item.data.extend_order_goods[0].goods_num}}</div>
                <div class="o_goods_num">￥{{item.data.extend_order_goods[0].goods_price}}</div>
              </div>
            </div>
            <div class="o_address" v-if="item.data.extend_order_extend.reciver_infos">
              {{item.data.extend_order_extend.reciver_infos.combine_detail}}
              {{item.data.extend_order_extend.reciver_infos.address}}
            </div>
            <div class="o_lookmore" @click="goOrder">
              <div class="o_lookmore_text">查看更多订单</div>
              <img :src="imgHttp+'rightArrow.png'" class="o_lookmore_image">
            </div>
          </template>
          <template v-else>
            <div class="auto_text pb2">您暂时还没有订单</div>
          </template>
        </div>
        <!-- 常见问题 -->
        <div v-if="item.type==2 && item.msgtype=='question'" :key="index" class="msgitem other">
          <img :src="item.user_info.logo" alt="头像" class="avatarUrl">
          <div class="other_msg">
            <div class="other_msg_name">{{item.user_info.store_name}}</div>
            <div class="other_msg_text auto pb2">
              <div class="title">常见问题</div>
              <template v-if="item.data.length>0">
                <div class="question_item" v-for="(q_item,q_index) in item.data" :key="q_item.id"
                  :style="{'border-top':q_index!=0?'0.01rem dashed #F4F4F4':''}"
                  @click="questionMe(q_item, item.user_info)">
                  <div class="q_text1">· {{q_item.asking}}？</div>
                </div>
              </template>
              <template v-else>
                <div class="auto_text">商家暂时还没有设置常见问题</div>
              </template>
            </div>
          </div>
        </div>
        <!-- 回复 -->
        <div class="msgitem other" v-if="item.type==2 && item.msgtype=='keyword'" :key="index">
          <img :src="item.user_info.logo" alt="头像" class="avatarUrl">
          <div class="other_msg">
            <div class="other_msg_name">{{item.user_info.store_name}}</div>
            <div class="other_msg_text">{{item.msg}}</div>
          </div>
        </div>
        <!-- 链接 -->
        <div v-if="item.type==2 && item.msgtype=='links'" :key="index" class="auto pb2">
          <div class="title">链接</div>
          <a :href="item.data" v-if="item.data">{{item.data}}</a>
          <div class="auto_text" v-else>商家暂时还没有设置链接</div>
        </div>
        <!-- 优惠券 -->
        <div v-if="item.type==2 && item.msgtype=='coupon'" :key="index" class="auto">
          <div class="title">优惠券</div>
          <div v-if="item.data.list.length>0">
            <div class="coupon_item" v-for="c_item in item.data.list" :key='c_item.id' @click="getCoupons(c_item.id)">
              <div class="coupon_item_left"
                :style="{'background-color':c_item.status==1?'rgba(245,52,43,0.4)':'#E1E1E6'}">
                <div class="item_price1" v-if="c_item.type==1 || c_item.type==2"
                  :style="{'color':c_item.status==1?'#F5342B':'#ffffff'}">
                  <span class="item_price1_text">￥</span>{{c_item.subtract}}
                </div>
                <div class="item_price1" v-else-if="c_item.type==3"
                  :style="{'color':c_item.status==1?'#F5342B':'#ffffff'}">
                  {{c_item.discount}}<span class="item_price1_text">折</span>
                </div>
                <div class="item_price2" v-if="c_item.type==1 || c_item.type==3"
                  :style="{'color':c_item.status==1?'#F5342B':'#ffffff'}">满{{c_item.consume_full}}可用
                </div>
              </div>
              <div class="item_text">
                <div class="item_text1" :style="{'color':c_item.status==1?'#333333':'#969699'}">{{c_item.name}}</div>
                <div class="item_text2">有效期：{{handleTime(c_item['start_time'],1)}}-{{handleTime(c_item['end_time'],1)}}
                </div>
              </div>
            </div>
          </div>
          <div class="o_lookmore" @click="goCoupon">
            <div class="o_lookmore_text">看看领券中心吧</div>
            <img :src="imgHttp+'rightArrow.png'" class="o_lookmore_image">
          </div>
        </div>
        <!-- 猜你喜欢 -->
        <div v-if="item.type==2 && item.msgtype=='likes'" :key="index" class="auto">
          <div class="title">猜你喜欢</div>
          <div class="goods_group">
            <div class="likes_item" v-for="l_item in item.data.list.slice(0,9)" :key="l_item.id"
              @click="goGoods(l_item.id)">
              <img :src="l_item.goods_picture" class="likes_image">
              <div class="likes_price">￥{{l_item.goods_price}}</div>
            </div>
          </div>
        </div>
        <!-- 我的消息 -->
        <div v-if="item.type==0" :key="index" class="msgitem me">
          <div class="me_msg" v-if="item.msgtype=='text'" v-html="dealMsg(item.msg)"></div>
          <div class="me_msg" v-if="item.msgtype=='image'">
            <img :src="item.msg" style="max-width: 4.16rem;height: auto;" @click="lookImage(item.msg)">
          </div>
          <div class="other_msg_goods" v-if="item.msgtype=='miniprogrampage'" style="margin-right: 0.16rem;"
            @click="goGoods(item.msg.id)">
            <img :src="item.msg.thumb" class="msg_goods_image">
            <div class="msg_goods_info">
              <div class="msg_goods_name">{{item.msg.title}}</div>
              <div class="msg_goods_price">￥{{item.msg.goods_price}}</div>
            </div>
          </div>
          <img :src="$global.avatarUrl?$global.avatarUrl:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/live/l_avator.png'" alt="头像" class="avatarUrl">
        </div>
        <!-- 提示 -->
        <div class="mb34 toast">
          <span v-if="item.type==3">{{item.msg}}</span>
        </div>
        <!-- 时间 -->
        <div class="mb34 toast">
          <span v-if="item.type==4">{{showTime(item.time)}}</span>
        </div>
      </template>
    </div>
    <footer>
      <!-- 商品 -->
      <div class="goods" v-show="showGoods" @click="sendGoods" v-if="goodsInfo">
        <img :src="goodsInfo.thumb" class="goods_image">
        <div class="goods_right">
          <div class="goods_name">{{goodsInfo.title}}</div>
          <div class="goods_price">￥{{goodsInfo.goods_price}}</div>
        </div>
      </div>

      <div class="tools">
        <div class="tools_box">
          <div class="iwant">我想</div>
          <div class="tools_item" v-for="item in menu" :key="item.type" @click="getMenu(item)">{{item.label}}</div>
        </div>
      </div>
      <div class="inputrow">
        <div class="inputbox">
          <van-field v-model="msg" rows="1" autosize type="textarea" placeholder="请输入你要咨询的内容" @focus="focusTextarea"
            class="textarea" />
          <img :src="imgHttp+'emoji.png'" class="emoji" @click="showEmojis">
        </div>
        <van-button type="primary" size="small" v-if="msg" class="send" @click="sendMsg">发送</van-button>
        <van-uploader :after-read="afterRead" v-else accept="image/*">
          <img :src="imgHttp+'add.png'" class="add">
        </van-uploader>
      </div>
    </footer>
    <div class="emojiPop" :style="{'height':showEmojiPop?'4rem':'0'}">
      <div class="emojibox">
        <div class="emoji_item" v-for="(item,index) in emojiArr" :key="index" @click="addEmoji(item)">{{item}}</div>
      </div>
      <div class="emojiTab">
        <div v-for="(item,index) in expressionList" :key="item.name"
          :class="['emojiTab_item',index==emojiActive?'emojiActive':'']" @click="changeEmojiTab(index)">{{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import expressionList from '@/utils/emojis'
  import {
    ImagePreview,
    Toast
  } from 'vant';

  export default {
    data() {
      return {
        path: "wss://digitalizesocket.cnweisou.net?user_token=", // 正式线
        // path: "wss://digitizationsocket.5g-center.com?user_token=", // 测试线
        imgHttp: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Chatroom/',
        showEmojiPop: false,
        expressionList: expressionList,
        msg: '',
        emojiActive: 0,
        emojiArr: [],
        // msgArrPanel: [],
        goodsInfo: {},
        showGoods: false, // 商品
        showInput: false,
        menu: [],
        imageArr: []
      }
    },
    created() {
      this.initSocket()
      this.getEmojiArr()
      this.getOrder()
      this.$nextTick(() => {
        this.showInput = true
      })
      // 商品
      if (this.$global.goodsinfo) {
        this.showGoods = true
        this.goodsInfo = JSON.parse(decodeURIComponent(this.$global.goodsinfo))
        console.log(JSON.parse(decodeURIComponent(this.$global.goodsinfo)))
      }

    },
    beforeDestroy() {
      this.closeWs()
    },
    computed: {
      msgArrPanel() {
        return this.$store.state.msg ? this.$store.state.msg : []
      }
    },
    watch: {
      msgArrPanel(val) {
        setTimeout(() => {
          this.$refs.chatPanel.scrollTop = this.$refs.chatPanel.scrollHeight
        }, 100)
        this.imageArr = []
        val.forEach(item => {
          if (item.msgtype === 'image') {
            this.imageArr.push(item.msg)
          }
        })
      }
    },
    methods: {
      getOrder(){
        this.$request.post('/Api/CustomerService/getCostomerMenu')
          .then(res => {
            if(res.code==0){
              let data = res.result
              if(data.config_data){
                let arr = []
                for(let i in data.config_data){
                  arr.push({
                    type:i,
                    label:data.config_data[i].menu_name
                  })
                }
                this.menu = [...arr]
              }else{
                let arr = []
                data.forEach(item=>{
                  arr.push({
                    type:item.type,
                    label:item.name
                  })
                })
                this.menu = [...arr]
              }
            }
          })

      },
      // 初始化Socket
      initSocket() {
        if (typeof(WebSocket) === "undefined") {
          alert("您的浏览器不支持socket")
        } else {
          console.log('初始化 WebSocket')
          // 实例化socket
          this.$global.socket = new WebSocket(this.path + this.$global.token)
          // 监听socket连接
          this.$global.socket.onopen = this.open
          // 监听socket错误信息
          this.$global.socket.onerror = this.error
          // 监听socket消息
           this.$global.socket.onclose = this.closeMs
          this.$global.socket.onmessage = this.getMessage
        }
      },
      closeMs(){
        console.log("close，socket已经关闭")
      },
      open: function() {
        console.log("open，socket连接成功")
      },
      error: function() {
        console.log("error，连接错误")
      },
      getMessage: function(msg) {
        // console.log('getMessage，' + msg.data)
        let data = JSON.parse(msg.data)
        if (data.action === 101) {
          if (data.content.openid) {
            this.$global.openid = data.content.openid
            this.$global.uid = data.content.uid
            console.log(data.content.uid)
            this.$global.avatarUrl = data.content.avatar

            // 消息
            let msg = JSON.parse(localStorage.getItem('msg'))
            if (msg) {
              msg.forEach(item => {
                if (item.uid === data.content.uid * 1) {
                  this.$store.commit("handleMsg", item.data)
                }
              })
            }
          }else {
            console.log(JSON.stringify(data))
            Toast({
              duration: 0, // 持续展示 toast
              message: data.content
            });
          }
        }
        if (data.action === 104) {
          this.$handleMsg.saveMsg({
            type: 1,
            msg: data.content,
            name: data.fromUserFd.nickname || data.fromUserFd.username,
            avatarUrl: data.fromUserFd.avatar,
            time: data.sendTime,
            msgtype: data.type
          })
        }
        if (data.data || data.type === 'links') {
          this.$handleMsg.saveMsg({
            type: 2,
            data: data.data,
            time: Math.floor(new Date().getTime() / 1000),
            msgtype: data.type,
            user_info: data.user_info ? data.user_info : null
          })
        }
      },
      closeWs: function() {
        if(this.$global.socket){
          this.$global.socket.close();
        }
      },
      getEmojiArr() {
        this.emojiArr = expressionList[this.emojiActive].list
      },
      addEmoji(str) {
        this.msg += str
      },
      showEmojis() {
        this.showEmojiPop = true
      },
      focusTextarea() {
        this.showEmojiPop = false
      },
      clickChatroom() {
        this.showEmojiPop = false
      },
      changeEmojiTab(index) {
        this.emojiActive = index
        this.getEmojiArr()
      },
      sendMsg() {
        let params = {
          controller: "broadcast",
          action: "personBroadcast",
          params: {
            content: this.msg,
            msgtype: "text",
            from: this.$global.openid,
            to: this.$global.uniacid
          }
        }
        this.$global.socket.send(JSON.stringify(params)) // 发送给服务端
        this.$handleMsg.saveMsg({
          type: 0, // 1--他人 0--自己 2--自助菜单
          msg: this.msg,
          time: Math.floor(new Date().getTime() / 1000),
          msgtype: 'text'
        })
        this.msg = ''
      },
      dealMsg(str) {
        if (typeof str === 'string') {
          return str.replace(/\n/g, '<br>')
        } else {
          let strN = ''
          str.forEach(item => {
            strN += '回复【' + item + '】' + item + '<br>'
          })
          return strN
        }
      },
      // 上传图片
      afterRead(file) {
        let formData = new FormData()
        formData.append('file', file.file)
        this.$request.uploadFile('/api/upload/addImage', formData)
          .then(res => {
            if (res.code === 0) {
              let img = res.result.path
              let params = {
                controller: "broadcast",
                action: "personBroadcast",
                params: {
                  content: img,
                  msgtype: "image",
                  from: this.$global.openid,
                  to: this.$global.uniacid
                }
              }
              this.$global.socket.send(JSON.stringify(params)) // 发送给服务端
              this.$handleMsg.saveMsg({
                type: 0, // 1--他人 0--自己
                msg: img,
                time: Math.floor(new Date().getTime() / 1000),
                msgtype: 'image'
              })
            }
          })
      },
      // 自助菜单
      getMenu(item) {
        let params = {
          controller: "SelfMenu",
          action: "handleSelfMenuMessage",
          params: {
            type: item.type,
            from: this.$global.openid,
            to: this.$global.uniacid
          }
        }
        this.$global.socket.send(JSON.stringify(params)) // 发送给服务端
        // this.$handleMsg.saveMsg({
        //   type: 0, // 1--他人 0--自己
        //   msg: item.label,
        //   time: Math.floor(new Date().getTime() / 1000),
        //   msgtype: 'text'
        // })
      },
      // 查看图片
      lookImage(img) {
        this.imageArr.forEach((item, index) => {
          if (item === img) {
            ImagePreview({
              images: this.imageArr,
              startPosition: index,
              loop: false
            })
          }
        })
      },
      // 自助--常见问题
      questionMe(item, userinfo) {
        this.$handleMsg.saveMsg({
          type: 0, // 1--他人 0--自己 2--自助菜单
          msg: item.asking,
          time: Math.floor(new Date().getTime() / 1000),
          msgtype: 'text'
        })
        this.$handleMsg.saveMsg({
          type: 2, // 1--他人 0--自己 2--自助菜单
          msg: item.answer,
          time: Math.floor(new Date().getTime() / 1000),
          msgtype: 'keyword',
          user_info: userinfo
        })
      },
      // 加载图片
      loadImg() {
        setTimeout(() => {
          this.$refs.chatPanel.scrollTop = this.$refs.chatPanel.scrollHeight
        }, 100)
      },
      // 发送商品
      sendGoods() {
        let params = {
          controller: "broadcast",
          action: "personBroadcast",
          params: {
            content: {
              goodsid: this.goodsInfo.id
            },
            msgtype: "miniprogrampage",
            from: this.$global.openid,
            to: this.$global.uniacid
          }
        }
        this.$global.socket.send(JSON.stringify(params)) // 发送给服务端
        this.$handleMsg.saveMsg({
          type: 0, // 1--他人 0--自己
          msg: this.goodsInfo,
          time: Math.floor(new Date().getTime() / 1000),
          msgtype: 'miniprogrampage'
        })
        this.showGoods = false
      },
      // 优惠券中心
      goCoupon() {
        uni.navigateTo({
          url: '/pages/collectCoupon/index'
        });
      },
      // 订单中心
      goOrder() {
        uni.navigateTo({
          url: '/pages/orderCenter/index?id=1'
        });
      },
      // 商品详情
      goGoods(id) {
        uni.navigateTo({
          url: '/pages/goodsDetail/index?id=' + id,
          fail:(res)=>{
            console.log(res)
          }
        });
      },
      //处理时间戳
      handleTime(time, num) {
        // num=1 年月日 num=2 年月日时分秒
        let date = new Date(time * 1000)
        let year = date.getFullYear()
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        if (num == 1) {
          return year + '-' + month + '-' + d
        } else {
          let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
          let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
          let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
          return year + '-' + month + '-' + d + ' ' + h + ':' + m + ':' + s
        }
      },
      // 领取券
      getCoupons(id) {
        this.$request.post('/api/Coupon/getCoupon', {
            id
          })
          .then(res => {
            if (res.code === 0) {
              this.$handleMsg.saveMsg({
                type: 3,
                msg: '领取成功',
                time: Math.floor(new Date().getTime() / 1000),
                msgtype: 'text'
              })
            } else {
              this.$handleMsg.saveMsg({
                type: 3,
                msg: res.msg,
                time: Math.floor(new Date().getTime() / 1000),
                msgtype: 'text'
              })
            }
          })

      },
      // 显示时间
      showTime(time) {
        let time2 = time * 1000 //时间戳
        let date = new Date(time2)
        let year = date.getFullYear()
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        let today = new Date()
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        let otime = today.getTime() //今天零点时间戳
        let oweek = today.getDay()>0?today.getDay()-1:today.getDay()+6//今天零点到本周一过了几天
        let onedaytime = 24*60*60*1000 //一天毫秒数
        let mondaytime =  otime - oweek*onedaytime //本周一时间戳
        let weekarr = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']
        if(time2>=otime){
          return h + ':' + m
        }else if(time2<otime && time2>=otime-onedaytime){
          return '昨天 '+ h + ':' + m
        }else if(time2>=mondaytime && time2<otime-onedaytime){
         return weekarr[date.getDay()] + ' ' + h + ':' + m
        }else{
          return  year + '年' + month + '月' + d + '日 ' + h + ':' + m
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chatroom {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 0.32rem;
  }

  .main {
    flex: 1;
    background-color: #eeeeee;
    padding: 0.36rem 0.30rem 0;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .msgitem {
    display: flex;
    margin-bottom: 0.34rem;
    width: 100%;
  }

  .avatarUrl {
    width: 0.80rem;
    height: 0.80rem;
    border-radius: 50%;
  }

  .other_msg {
    margin-left: 0.13rem;
  }

  .other_msg_name {
    font-size: 0.24rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
    margin-bottom: 0.20rem;
  }

  .other_msg_text {
    background-color: #fff;
    padding: 0.16rem 0.2rem;
    border: 0.02rem solid #e6e6e6;
    border-radius: 0.16rem;
    line-height: 0.48rem;
    font-size: 0.30rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    max-width: 4.16rem;
    min-width: 0.4rem;
    white-space: normal;
    overflow: hidden;
  }

  .other_msg_goods {
    width: 4.16rem;
    background-color: #fff;
    padding: 0.16rem 0.2rem;
    border: 0.02rem solid #e6e6e6;
    border-radius: 0.16rem;
    display: flex;

    .msg_goods_image {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.2rem;
    }

    .msg_goods_info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .msg_goods_name {
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .msg_goods_price {
        font-size: 0.28rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FF0000;
      }
    }
  }

  .auto {
    width: 6.32rem;
    background: #FFFFFF;
    border-radius: 0.08rem;
    padding: 0 0.2rem;
    margin-bottom: 0.34rem;

    .title {
      height: 0.74rem;
      line-height: 0.74rem;
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FF0000;
    }

    .auto_text {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 0.42rem;
    }
  }

  .q_text1 {
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5654F3;
    line-height: 0.5rem;
  }

  .order {
    .o_goods {
      display: flex;
      padding-bottom: 0.3rem;
      border-bottom: 0.02rem solid #F4F4F4;

      .goods_imagebox {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.2rem;
        position: relative;

        .o_goods_image {
          width: 1.4rem;
          height: 1.4rem;
        }

        .goods_allnum {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1.4rem;
          height: 0.4rem;
          background-color: rgba(0, 0, 0, 0.2);
          font-size: 0.24rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.4rem;
          text-align: center;
        }
      }

      .o_goods_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .o_goods_name {
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .o_goods_num {
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .o_address {
      padding: 0.3rem 0;
      border-bottom: 0.02rem solid #F4F4F4;
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  .coupon_item {
    height: 1.8rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.02rem 0.15rem 0.01rem rgba(204, 200, 201, 0.24);
    border-radius: 0.16rem;
    margin-bottom: 0.24rem;
    overflow: hidden;
    padding-right: 0.24rem;
    display: flex;
    align-items: center;
    position: relative;

    .coupon_item_left {
      width: 1.8rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-right: 0.2rem;

      &::before {
        content: "";
        position: absolute;
        width: 0.24rem;
        height: 0.24rem;
        top: -0.12rem;
        border-radius: 50%;
        right: -0.12rem;
        background-color: #FFFFFF;
        z-index: 10;
        box-shadow: 0 0.244rem 0 -0.08rem #FFFFFF, 0 0.408rem 0 -0.08rem #FFFFFF,
          0 0.572rem 0 -0.08rem #FFFFFF, 0 0.736rem 0 -0.08rem #FFFFFF,
          0 0.90rem 0 -0.08rem #FFFFFF, 0 1.064rem 0 -0.08rem #FFFFFF,
          0 1.228rem 0 -0.08rem #FFFFFF, 0 1.392rem 0 -0.08rem #FFFFFF,
          0 1.556rem 0 -0.08rem #FFFFFF, 0 1.80rem 0 0 #FFFFFF;
      }

      .item_price1 {
        font-size: 0.4rem;
        font-family: Source Han Sans CN;
        font-weight: bold;

        .item_price1_text {
          font-size: 0.24rem;
          font-weight: normal;
        }
      }

      .item_price2 {
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-top: 0.10rem;
      }
    }

    .item_text {
      flex: 1;

      .item_text1 {
        font-size: 0.28rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      .item_text2 {
        font-size: 0.22rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 0.18rem;
      }
    }
  }

  .o_lookmore {
    height: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .o_lookmore_text {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

    .o_lookmore_image {
      width: 0.28rem;
      height: 0.28rem;
    }
  }

  .toast {
    text-align: center;

    span {
      border-radius: 0.28rem;
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      color: #FFFFFF;
      text-align: center;
      background-color: rgba(204, 204, 204, 0.6);
      padding: 0.04rem 0.3rem;
    }
  }

  .pb2 {
    padding-bottom: 0.2rem;
  }

  .mb34 {
    margin-bottom: 0.34rem;
  }

  .me {
    justify-content: flex-end;
  }

  .goods_group {
    display: flex;
    flex-wrap: wrap;

    .likes_item {
      width: 1.87rem;
      height: 1.87rem;
      position: relative;
      margin-bottom: 0.2rem;

      &:not(:nth-child(3n+3)) {
        margin-right: 0.26rem;
      }
    }

    .likes_image {
      width: 1.87rem;
      height: 1.87rem;
    }

    .likes_price {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.52rem;
      background-color: rgba(0, 0, 0, 0.3);
      line-height: 0.52rem;
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
  }

  .me_msg {
    padding: 0.16rem 0.2rem;
    border-radius: 0.16rem;
    line-height: 0.48rem;
    font-size: 0.30rem;
    color: #ffffff;
    background-image: linear-gradient(120deg, #FE315A, #F5342B, #F65A47);
    max-width: 4.16rem;
    min-width: 0.4rem;
    margin-right: 0.16rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
  }

  footer {
    background-color: #f6f5f5;
    padding: 0.16rem 0.30rem 0.25rem;
    box-sizing: border-box;
    position: relative;
  }

  .goods {
    position: absolute;
    top: -1.90rem;
    right: 0.30rem;
    height: 1.88rem;
    width: 3.92rem;
    box-sizing: border-box;
    background: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Chatroom/popup.png') no-repeat;
    background-size: cover;
    padding-top: 0.28rem;
    display: flex;
  }

  .goods_image {
    width: 1.06rem;
    height: 1.06rem;
    border-radius: 0.08rem;
    margin-left: 0.23rem;
    margin-right: 0.18rem;
  }

  .goods_right {
    flex: 1;
    overflow: hidden;
  }

  .goods_name {
    font-size: 0.3rem;
    margin-bottom: 0.24rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .goods_price {
    font-size: 0.26rem;
    color: #D70B0D;
  }

  .tools {
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  .tools_box {
    display: flex;
    align-items: center;
  }

  .iwant {
    margin-right: 0.18rem;
    font-size: 0.26rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
  }

  .tools_item {
    margin-left: 0.18rem;
    height: 0.54rem;
    padding: 0 0.24rem;
    border: 0.02rem solid #CCCCCC;
    font-size: 0.26rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    border-radius: 0.27rem;
    flex-shrink: 0;
  }

  .inputrow {
    display: flex;
    align-items: flex-end;
    margin-top: 0.18rem;
  }

  .inputbox {
    display: flex;
    // align-items: flex-end;
    padding: 0.12rem 0.26rem 0.08rem;
    background-color: #fff;
    border-radius: 0.08rem;
    margin-right: 0.20rem;
    width: 5.90rem;
  }

  .textarea {
    flex: 1;
    font-size: 0.28rem;
    border: none;
    color: #333333;
    display: flex;
    align-items: center;

    &::-webkit-input-placeholder {
      color: #999999;
      font-size: 0.28rem;
    }
  }

  .van-cell {
    padding: 0;

    &::after {
      border: none;
    }
  }

  .emoji {
    height: 0.61rem;
    height: 0.60rem;
    margin-left: 0.26rem;
    align-self: flex-end;
  }

  .add {
    width: 0.57rem;
    height: 0.56rem;
    display: block;
    transform: translateY(-0.13rem);
  }

  .send {
    transform: translateY(-0.13rem);
    white-space: nowrap;
    padding: 0 0.18rem;
    height: 0.56rem;
  }

  // emoji
  .emojiPop {
    height: 4.00rem;
    display: flex;
    flex-direction: column;
    transition: height 0.2s;
    overflow: hidden;
  }

  .emojibox {
    flex: 1;
    padding: 0.15rem 0.19rem;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .emoji_item {
    width: 0.89rem;
    text-align: center;
    font-size: 0.42rem;
    margin-bottom: 0.10rem;
  }

  .emojiTab {
    display: flex;
    border-top: 0.01rem solid #eee;
  }

  .emojiTab_item {
    height: 0.70rem;
    flex: 1;
    text-align: center;
    line-height: 0.70rem;
    font-size: 0.3rem;

    &:not(:last-child) {
      border-right: 0.01rem solid #eee;
    }
  }

  .emojiActive {
    background-color: #eee;
  }
</style>
